const TELEMETRY_KEY = 'insights-query-telemetry';
const BASE_TELEMETRY_OPTIONS = { bubbles: true, cancelable: true };
const RENDER_SERIES_TABLE = `series-table`;
const RENDER_LINE_CHART = `line-chart`;
const RENDER_BAR_CHART = `bar-chart`;
class InsightsQueryElement extends HTMLElement {
    connectedCallback() {
        this.executeQuery();
    }
    get query() {
        let query = this.getAttribute('query');
        if (query) {
            return query;
        }
        const queryContainerId = this.getAttribute('query-container-id');
        if (queryContainerId) {
            const queryContainer = document.getElementById(queryContainerId);
            if (queryContainer) {
                query = queryContainer instanceof HTMLInputElement ? queryContainer.value : queryContainer.textContent;
            }
        }
        return query;
    }
    set query(val) {
        if (val) {
            this.setAttribute('query', val);
        }
        else {
            this.removeAttribute('query');
        }
    }
    get apiUrl() {
        return this.getAttribute('api-url');
    }
    set apiUrl(val) {
        if (val) {
            this.setAttribute('api-url', val);
        }
        else {
            this.removeAttribute('api-url');
        }
    }
    get authUrl() {
        return this.getAttribute('auth-url');
    }
    set authUrl(val) {
        if (val) {
            this.setAttribute('auth-url', val);
        }
        else {
            this.removeAttribute('auth-url');
        }
    }
    async executeQuery() {
        const { query, render } = this.parseQuery();
        const apiUrl = this.apiUrl;
        if (!apiUrl || !query || !render) {
            return;
        }
        const { token, scope } = await this.fetchTokenAndScope();
        if (!token || !scope) {
            return;
        }
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify({ query }),
                headers: { Authorization: token, 'X-Auth-Scope': scope, 'Content-Type': 'application/json' }
            });
            if (response.status !== 200) {
                throw new Error(`Insights API returned status code: ${response.status}`);
            }
            const decodedResponse = await response.json();
            const { data, errors } = decodedResponse;
            if (!data || !errors) {
                this.innerHTML = `Invalid response format`;
                throw new Error(`Either no data is returned or data response has changed`);
            }
            if (errors && errors.hasErrors) {
                throw new Error(`Insights API Error: ${errors.errorMessage}`);
            }
            const formattedSeries = this.formatData(data);
            const validRenderTypes = [RENDER_SERIES_TABLE, RENDER_LINE_CHART, RENDER_BAR_CHART];
            if (!validRenderTypes.includes(render)) {
                throw new Error(`Query attribute contains unknown render type: ${render}`);
            }
            this.innerHTML = `<${render} series="${encodeURI(JSON.stringify(formattedSeries))}"></${render}>`;
            this.dispatchEvent(new CustomEvent(TELEMETRY_KEY, Object.assign(Object.assign({}, BASE_TELEMETRY_OPTIONS), { detail: { incrementKey: 'execute-success' } })));
        }
        catch (e) {
            this.dispatchEvent(new CustomEvent(TELEMETRY_KEY, Object.assign(Object.assign({}, BASE_TELEMETRY_OPTIONS), { detail: { incrementKey: 'execute-error' } })));
            throw new InsightsDataFetchError(e.message);
        }
    }
    async fetchTokenAndScope() {
        const authUrl = this.authUrl;
        if (!authUrl) {
            return { token: null, scope: null };
        }
        try {
            const authResponse = await fetch(authUrl, {
                headers: {
                    Accept: 'application/json'
                }
            });
            const { token, scope } = await authResponse.json();
            return { token, scope };
        }
        catch (e) {
            this.dispatchEvent(new CustomEvent(TELEMETRY_KEY, Object.assign(Object.assign({}, BASE_TELEMETRY_OPTIONS), { detail: { incrementKey: 'token-fetch-error' } })));
            throw new InsightsTokenFetchError(e.message);
        }
    }
    formatData(rawData) {
        return [rawData.columns.map(d => d.name), ...rawData.rows];
    }
    parseQuery() {
        const query = this.query;
        if (!query) {
            return { query: null, render: null };
        }
        const regex = /--\s*render\s+(?<render>[^\s]+)/i;
        const match = query.match(regex);
        const render = match && match.groups !== undefined ? match.groups['render'] : 'series-table';
        return { query, render };
    }
}
class InsightsTokenFetchError extends Error {
    constructor(message) {
        super(message);
        this.name = 'InsightsTokenFetchError';
    }
}
class InsightsDataFetchError extends Error {
    constructor(message) {
        super(message);
        this.name = 'InsightsDataFetchError';
    }
}
export default InsightsQueryElement;
if (!window.customElements.get('insights-query')) {
    window.InsightsQueryElement = InsightsQueryElement;
    window.InsightsTokenFetchError = InsightsTokenFetchError;
    window.InsightsDataFetchError = InsightsDataFetchError;
    window.customElements.define('insights-query', InsightsQueryElement);
}
