import '@github/insights-query-element'
import {sendStats} from '../stats'

window.addEventListener('insights-query-telemetry', (event: Event) => {
  const e = event as CustomEvent
  const stats: PlatformBrowserStat = {}
  let eventIncrementKey = e.detail.incrementKey
  if (eventIncrementKey) {
    eventIncrementKey = `INSIGHTS_QUERY_${eventIncrementKey.replace(/-/g, '_').toUpperCase()}`
    const incrementKey = eventIncrementKey as PlatformBrowserIncrementKey
    if (incrementKey) {
      stats['incrementKey'] = incrementKey
    }
  }

  if (Object.keys(stats).length > 0) {
    sendStats(stats)
  }
})
